//import { AxiosRequestConfig } from "axios";
import { AxiosRequestConfig } from "axios";
import { baseURL, instance } from "./axios";
import {
  GA4EventsNames,
  HttpCodes,
  ReqSuccessMessage,
  TrackGoogleAnalyticsEvent,
  getReqJsonModalGA4,
} from "../../app/pages/Home/utils";
import { urlWithSeparator } from "../../app/helper/UrlSeperator";
// TODO Should use the token in the userData context
export const httpGet = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.get(urlWithSeparator(requestPath), {
    ...config,
  });

  if (
    axiosResponse !== null &&
    axiosResponse.status === HttpCodes.success &&
    axiosResponse.data.type === ReqSuccessMessage
  ) {
    var jsonBody = getReqJsonModalGA4(
      "",
      "success",
      "N/A",
      baseURL + requestPath,
      { success: "success" }
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiSuccess,
      requestPath,
      jsonBody
    );
  } else {
    jsonBody = getReqJsonModalGA4(
      "",
      "Fail",
      axiosResponse.data.response,
      baseURL + requestPath,
      axiosResponse.data.data
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiFailed,
      GA4EventsNames.apiFailed,
      jsonBody
    );
  }
  return axiosResponse.data;
};
export const httpGetWithAuth = async (
  requestPath: string,
  config?: AxiosRequestConfig,
  userToken?: string
): Promise<any> => {
  const axiosResponse = await instance.get(urlWithSeparator(requestPath), {
    headers: {
      Authorization: "Bearer " + userToken,
    },
    ...config,
  });
  if (
    axiosResponse !== null &&
    axiosResponse.status === HttpCodes.success &&
    axiosResponse.data.type === ReqSuccessMessage
  ) {
    var jsonBody = getReqJsonModalGA4(
      "",
      "success",
      "N/A",
      baseURL + requestPath,
      { success: "success" }
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiSuccess,
      requestPath,
      jsonBody
    );
  } else {
    jsonBody = getReqJsonModalGA4(
      "",
      "Fail",
      axiosResponse.data.response,
      baseURL + requestPath,
      axiosResponse.data.data
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiFailed,
      GA4EventsNames.apiFailed,
      jsonBody
    );
  }
  return axiosResponse.data;
};
export const httpPost = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig,
  userToken?: string
): Promise<any> => {
  const axiosResponse = await instance.post(
    urlWithSeparator(requestPath),
    data,
    {
      headers: {
        Authorization: "Bearer " + userToken,
      },
      ...config,
    }
  );
  if (
    axiosResponse !== null &&
    axiosResponse.status === HttpCodes.success &&
    axiosResponse.data.type === ReqSuccessMessage
  ) {
    var jsonBody = getReqJsonModalGA4(
      "",
      "success",
      "N/A",
      baseURL + requestPath,
      { success: "success" }
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiSuccess,
      requestPath,
      jsonBody
    );
  } else {
    jsonBody = getReqJsonModalGA4(
      "",
      "Fail",
      axiosResponse.data.response,
      baseURL + requestPath,
      axiosResponse.data.data
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiFailed,
      GA4EventsNames.apiFailed,
      jsonBody
    );
  }
  return axiosResponse.data;
};
export const httpPostWithOutAuth = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.post(
    urlWithSeparator(requestPath),
    data,
    {
      ...config,
    }
  );
  if (
    axiosResponse !== null &&
    axiosResponse.status === HttpCodes.success &&
    axiosResponse.data.type === ReqSuccessMessage
  ) {
    var jsonBody = getReqJsonModalGA4(
      "",
      "success",
      "N/A",
      baseURL + requestPath,
      { success: "success" }
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiSuccess,
      requestPath,
      jsonBody
    );
  } else {
    jsonBody = getReqJsonModalGA4(
      "",
      "Fail",
      axiosResponse.data.response,
      baseURL + requestPath,
      axiosResponse.data.data
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiFailed,
      GA4EventsNames.apiFailed,
      jsonBody
    );
  }
  return axiosResponse.data;
};

export const httpPut = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.put(
    urlWithSeparator(requestPath),
    data,
    {
      headers: {
        Authorization: "Bearer null",
      },
      ...config,
    }
  );
  if (
    axiosResponse !== null &&
    axiosResponse.status === HttpCodes.success &&
    axiosResponse.data.type === ReqSuccessMessage
  ) {
    var jsonBody = getReqJsonModalGA4(
      "",
      "success",
      "N/A",
      baseURL + requestPath,
      { success: "success" }
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiSuccess,
      requestPath,
      jsonBody
    );
  } else {
    jsonBody = getReqJsonModalGA4(
      "",
      "Fail",
      axiosResponse.data.response,
      baseURL + requestPath,
      axiosResponse.data.data
    );
    TrackGoogleAnalyticsEvent(
      "Api Call",
      GA4EventsNames.apiFailed,
      GA4EventsNames.apiFailed,
      jsonBody
    );
  }
  return axiosResponse.data;
};

export const httpDelete = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.delete(urlWithSeparator(requestPath), {
    headers: {
      Authorization: "Bearer null",
    },
    ...config,
  });
  return axiosResponse.data;
};
export async function sendHttpReq(finalToken: string, apiURL: string) {
  var resJsonFinal = [];
  try {
    var tokenstr = finalToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenstr,
    };
    if (tokenstr != null) {
      var tokenR = await fetch(apiURL, { headers });
      const resJson = await tokenR.json();
      //console.log("---->"+JSON.stringify(resJson));
      resJsonFinal = JSON.parse(JSON.stringify(resJson));
    } else {
      resJsonFinal = [];
    }
  } catch (err) {
    resJsonFinal = [];
  }
  return resJsonFinal;
}
export async function sendHttpPost(
  finalToken: string,
  apiURL: string,
  JsonBody: any
) {
  var resJsonFinal = [];
  try {
    var tokenstr = finalToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenstr,
    };
    if (tokenstr != null) {
      var tokenR = await fetch(apiURL, {
        method: "POST",
        headers,
        body: JsonBody,
      });
      const resJson = await tokenR.json();
      //console.log("---->"+JSON.stringify(resJson));
      resJsonFinal = JSON.parse(JSON.stringify(resJson));
    } else {
      resJsonFinal = [];
    }
  } catch (err) {
    resJsonFinal = [];
  }
  return resJsonFinal;
}
